<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">题库管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">题库列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="题库名称" class="searchboxItem ci-full">
              <span class="itemLabel">题库名称:</span>
              <el-input
                v-model="questionbankName"
                type="text"
                size="small"
                placeholder="请输入题库名称"
                clearable
              />
            </div>
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
                v-model="areaId"
                :options="areatreeList"
                :props="propsarea"
                :disabled="disabled"
                clearable
                filterable
                size="small"
              ></el-cascader>
            </div>
            <span title="题库类别" class="searchboxItem ci-full flexcc">
              <span class="itemLabel">题库类别:</span>
              <el-cascader
                v-model="categoryCode"
                :options="TreeList"
                :props="propsTree"
                clearable
                filterable
                size="small"
              ></el-cascader>
            </span>
            <div title="是否上架" class="searchboxItem ci-full">
              <span class="itemLabel">是否上架:</span>
              <el-select
                v-model="questionBankState"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in questionBankStateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <el-button
              class="bgc-bv"
              style="margin:0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
            <el-button
              class="bgc-bv"
              style="margin:0 10px"
              round
              @click="handlAdd()"
              >新增</el-button
            >
          </div>
        </div>
        <h4 style="padding:8px 10px">订单总数量：{{ sumTotal }}</h4>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
                width="100px"
              />
              <el-table-column
                label="题库名称"
                align="left"
                prop="questionBankName"
                show-overflow-tooltip
                width="280"
                fixed
              />
              <el-table-column
                label="行政区划"
                align="left"
                show-overflow-tooltip
                prop="areaName"
                width="140"
              />
              <el-table-column
                label="题库类别"
                align="left"
                show-overflow-tooltip
                minWidth="180"
              >
                <template slot-scope="scope">{{
                  scope.row.questionCategory || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="苹果单价(元)"
                align="left"
                show-overflow-tooltip
                minWidth="140"
              >
                <template slot-scope="scope">{{ scope.row.iosPrice }}</template>
              </el-table-column>
              <el-table-column
                label="安卓单价(元)"
                align="left"
                show-overflow-tooltip
                minWidth="140"
              >
                <template slot-scope="scope">{{
                  scope.row.androidPrice
                }}</template>
              </el-table-column>
              <el-table-column
                label="小程序单价(元)"
                align="left"
                show-overflow-tooltip
                minWidth="140"
              >
                <template slot-scope="scope">{{
                  scope.row.miniappPrice
                }}</template>
              </el-table-column>
              <el-table-column
                label="题目数量"
                align="right"
                prop="questionBankNum"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.questionBankNum || 0 }}
                </template>
              </el-table-column>
              <el-table-column
                label="已审核题目数量"
                align="right"
                prop="auditNum"
                minWidth="140"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.auditNum || 0 }}
                </template>
              </el-table-column>
              <el-table-column
                label="已售数量"
                align="right"
                prop="sellNum"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ scope.row.sellNum || 0 }}
                </template>
              </el-table-column>
              <el-table-column label="是否上架" align="center" min-width="100">
                <div slot-scope="scope" class="table-switch">
                  <el-switch
                    :width="20"
                    v-model="scope.row.questionBankState"
                    active-value="10"
                    inactive-value="20"
                    active-color="#13ce66"
                    @change="
                      (val) => {
                        handleStu(val, scope.row.questionBankId);
                      }
                    "
                  ></el-switch>
                  <span>{{
                    scope.row.questionBankState == "20" ? "下架" : "上架"
                  }}</span>
                </div>
              </el-table-column>
              <el-table-column
                label="操作"
                fixed="right"
                align="center"
                width="120px"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="
                      handlEdit(
                        scope.row.questionBankId,
                        scope.row.areaName,
                        scope.row.questionBankName,
                        scope.row.questionCategory
                      )
                    "
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="
                      handlAudit(
                        scope.row.questionBankId,
                        scope.row.areaName,
                        scope.row.questionBankName,
                        scope.row.questionCategory,
                        'audit'
                      )
                    "
                    >审核</el-button
                  >
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="
                      handlExport(
                        scope.row.questionBankId
                      )
                    "
                    >导出</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
// import treePopupQuestion from "@/components/treePopupQuestion";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "questionbankList",
  components: {
    Empty,
    PageNum,
    // treePopupQuestion,
  },
  mixins: [List],
  data() {
    return {
      questionbankName: "", //题库名称
      params: {},
      ruleForm: {
        Trainingtype: "",
      },
      areatreeList: [],
      areaId: "",
      categoryCode: "",
      TreeList: [],
      propsTree: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      questionBankStateList: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "10",
          label: "是",
        },
        {
          value: "20",
          label: "否",
        },
      ],
      questionBankState: "",
      sumTotal: 0,
    };
  },
  created() {
    this.getareatree();
    this.getTree();
  },
   computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    /* tree */
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    /* 行政区划 */
    getareatree() {
      this.$post("/sys/area/tree?areaLevel=1", {}, 3000, true, 2).then(
        (ret) => {
          this.areatreeList = ret.data;
        }
      );
    },
    /* 题库类别 */
    getTree() {
      this.$post(
        "/miniapp/category/enable/tree",
        { disabledLevels: "1,2,3" },
        3000,
        true,
        2
      ).then((ret) => {
        this.TreeList = ret.data;
      });
    },
    /* 获取列表数据 */
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.questionbankName) {
        params.questionBankName = this.questionbankName;
      }
      if (this.questionBankState) {
        params.questionBankState = this.questionBankState;
      }
      if (this.categoryCode) {
        params.categoryCode = this.categoryCode;
      }
      this.doFetch(
        {
          url: "/question/bank/page",
          params,
          pageNum,
        },
        true,
        2
      );
      this.getNum(params);
    },
    getNum(params) {
      this.$post("/question/bank/sellNumTotalSum", params, 3000, true, 2)
        .then((ret) => {
          this.sumTotal = ret.data;
        })
        .catch((err) => {
          return;
        });
    },
    /* 新增 */
    handlAdd() {
      this.$router.push({
        path: "/web/Questionbank/questionbankAdd",
        query: {
          stu: "add",
        },
      });
      sessionStorage.setItem("questionJson", "");
    },
    /* 编辑 */
    handlEdit(questionBankId, areaName, questionBankName, questionCategory) {
      this.$router.push({
        path: "/web/Questionbank/questionbankAdd",
        query: {
          questionBankId,
          stu: "edit",
        },
      });
      let json = JSON.stringify({
        questionBankId,
        areaName,
        questionBankName,
        questionCategory,
      });
      sessionStorage.setItem("questionJson", json);
    },
    /* 审核 */
    handlAudit(
      questionBankId,
      areaName,
      questionBankName,
      questionCategory,
      stuAudit
    ) {
      this.$router.push({
        path: "/web/Questionbank/questionbankAdd",
        query: {
          questionBankId,
          stu: "edit",
          stuAudit,
        },
      });
      let json = JSON.stringify({
        questionBankId,
        areaName,
        questionBankName,
        questionCategory,
        stuAudit,
      });
      sessionStorage.setItem("questionJson", json);
    },
    /**
     * 修改上下架
     */
    handleStu(questionBankState, questionBankId) {
      this.$post(
        "/question/bank/up",
        {
          questionBankState,
          questionBankId,
        },
        3000,
        true,
        2
      ).then((res) => {
        if (res.status == "0") {
          this.$message({
            type: "success",
            message: questionBankState == "20" ? "下架成功" : "上架成功",
          });
          this.getData(-1);
        }
      });
    },
    /* 删除 */
    handlDelet(questionBankId) {
      this.doDel(
        {
          url: "/question/bank/delete",
          msg: "是否确认删除？",
          ps: {
            type: "post",
            data: { questionBankId },
          },
        },
        true,
        2
      );
    },
    handlExport(questionBankId) {
      this.$post('/question/bank/export',{questionBankId},3000,true,2).then(res => {
         if (res.status == "0") {
              let list = [];
              list.push(res.data);
              for (let item of list) {
                console.log(item);
                if (!this.downloadItems.includes(item.taskId)) {
                  this.$store.dispatch("pushDownloadItems", item.taskId);
                } else {
                  this.$message.warning(
                    "[" + item.fileName + "]已经申请下载,请耐心等待"
                  );
                }
              }
            } else {
              this.$message.error(re.message);
            }
      })
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 2.55) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1 + 40;
      }
      if (page) {
        tHeight -= 25;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        } else {
          this.getData();
        }
        // if (oldVal.query.stu == "add") {
        //   (this.recommendName = ""),
        //     (this.areaId = ""),
        //     (this.terminals = ""),
        //     (this.pageNum = 1);
        //   this.getData(-1);
        // }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less"></style>
